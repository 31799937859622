.earn {
    background-image: url(/themes/default/assets/images/icons/pattern.svg);
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    display: flex;
    align-items: center;
    position: relative;
    padding-top: 160px;
    padding-bottom: 160px;
    &::after {
        content: '';
        position: absolute;
        top: -1px;
        left: 0;
        width: 100%;
        height: 101%;
        z-index: 0;
        background: linear-gradient(180deg, #f6f8f9 0%, rgba(255, 255, 255, 0.95) 20%, rgba(255, 255, 255, 0.95) 70%, white 100%);    
    }
    &__content {
        z-index: 1;
        display: grid;
        grid-template-columns: 1fr 440px;
        grid-template-rows: 1fr;
        grid-column-gap: 8vw;
        width: 100%;
        align-items: start;
        h2 {
            color: $color-main;
            font-size: rem(29px);
            font-weight: 400;
            text-transform: uppercase;
            margin-bottom: 50px;
        }
        p {
            line-height: rem(32px);
            font-size: rem(16px);
        }
        .links {
            display: flex;
            justify-content: flex-start;
            margin-top: 60px;
            a {
                background-color: #1A4265;
                border-radius: 5px;
                padding: 5px 10px;
                display: flex;
                align-items: center;
                transition: ease 0.3s background-color;
                img {
                    width: 20px;
                }
                span {
                    color: #fff;
                    font-size: rem(16px);
                    margin-left: 10px;
                }
                &:first-of-type {
                    margin-right: 10px;
                }
                &:hover {
                    background-color: $color-main;
                }
            }
        }
    }
    .image {
        position: relative;
        .back {
            position: absolute;
            top: -80px;
            width: 100%;
            height: 100%;
            left: -8vw;
            max-width: 966px;
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 0;
                background-color: #DE8730;
                border-radius: 0 3px 3px 0;
            }
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 0;
                background-image: url('/themes/default/assets/images/inwestycja.jpg');
                background-position: right center;
                background-size: cover;
                border-radius: 0 3px 3px 0;
                mix-blend-mode: soft-light;
            }
        }    
        img {
            border-radius: 3px;
            z-index: 1;
            position: relative;
            height: auto;
        }
    }
}

@include mq($until: xl) {
    .earn {
        .image {
            .back {
                max-width: 80%;
                left: -100px;
            }
        }
    }
}
@include mq($until: lg) {
    .earn {
        .image {
            .back {
                left: -50px;
            }
        }
    }
}
@include mq($until: md) {
    .earn {
        padding-top: 100px;
        padding-bottom: 100px;
        &__content {
            grid-template-columns: 1fr;
            h2 {
                text-align: center;
            }
            .links {
                display: none;
            }
        }
        .image {
            margin-bottom: 70px;
            max-width: 600px;
            margin-left: auto;
            margin-right: auto;
            .back {
                max-width: 85vw;
            }
        }
    }
}
@include mq($until: sm) {
    .earn {
        .image {
            .back {
                left: -30px;
            }
        }
        &__content {
            p {
                font-size: rem(20px);
            }
            .links a span {
                font-size: rem(20px);
            }
        }
    }
}
@include mq($until: xs) {
    .earn {
        padding-bottom: 60px;
        &__content {
            .links {
                flex-direction: column;
                align-items: flex-start;
                row-gap: 20px;
            }
        }
        .image {
            .back {
                left: -15px;
            }
        }
    }
}
