.flat-list-page{
  &__front-image{
    img{
      display: block;
      width: 100%;
    }
  }
  &__main-text{
    margin: 100px 0 200px 0;
    @include mq($until: md) {
      margin: 50px 0 100px 0;
    }
    p{
      font-size: rem(20px);
    }
  }
}

.flat-list {
  @include mq($until: sm) {
    margin-top: 40px;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    @include mq($until: xs) {
      margin-top: 60px;
    }
  }
  &__head {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 2fr 1fr;
    justify-content: stretch;
    align-items: center;
    padding: 15px 20px;
    margin-bottom: 5px;
    background-color: #1A4265;
    border-radius: 3px;
    @include mq($until: xl) {
      grid-template-columns: 1fr 1fr 1fr 1fr 2fr 1fr;
    }
    @include mq($until: lg) {
      grid-template-columns: 1fr 1fr 1fr 1fr 2fr 1fr;
      grid-gap: 5px;
    }
    @include mq($until: xs) {
      display: none;
    }
    position: relative;

  }
  &__head-item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: rem(18px);
    text-transform: uppercase;
    color: #fff;
    &--action{
      cursor: pointer;
      transition: color .3s;
      &:hover{
        color: $color-main;
      }
    }
    &.active{
      color: $color-main;
    }
    i{
      margin-right: 5px;
      padding-bottom: 9px;
    }
    &[data-order="asc"]{
      i{
        transform: scale(1);
      }
    }
    &[data-order="desc"]{
      i{
        transform: scale(-1);
      }    
    }
    @include mq($until: xxl) {
      font-size: rem(16px);
    }

    @include mq($until: xl) {
      font-size: rem(13px);
    }
    
    &--left {
      justify-content: flex-start;
    }
    &--right {
      justify-content: flex-end;
    }
  }
  &__item {
    display: grid;
    padding: 0 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr 2fr 1fr;
    justify-content: stretch;
    align-items: center;
    transition: 0.3s;
    position: relative;
    background-color: #fff;
    margin-bottom: 10px;
    border-radius: 3px;
    box-shadow: 0 3px 6px rgba(9, 52, 82, 0.15);
    transition: ease 0.3s background-color;

    &.active {
      background-color: #DCECFA;
    }
    &:last-child {
      margin-bottom: 0;
    }
    @include mq($until: xs) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      grid-gap: 0;
      padding: 20px;
      border: 1px solid #DCECFA;
      box-shadow: unset;
    }
  }
  &__status {
    text-transform: uppercase;
  }
  &__info {
    display: flex;
    padding: 15px 0;
    justify-content: center;
    align-items: center;
    font-size: rem(16px);
    @include mq($until: lg) {
      font-size: rem(14px);
    }
    @include mq($until: xs) {
      justify-content: space-between;
      font-weight: $fw-bold;
      font-size: rem(18px);
      padding: 0;
      flex-basis: 100%;
      margin-bottom: 5px;
    }
    &--left {
      justify-content: flex-start;
    }
    &--right {
      justify-content: flex-end;
    }

  }
  &__item-value{
    position: relative;
  }
  &__item-label {
    display: none;
    @include mq($until: xs) {
      display: block;
      margin-right: 10px;
      font-weight: $fw-regular;
      font-size: rem(20px);
      display: inline;
    }
  }
  &__item-value {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: rem(16px);
    font-weight: 500;
    @include mq($until: lg) {
      font-size: rem(14px);
    }
    @include mq($until: xs) {
      font-size: rem(18px);
      font-weight: $fw-bold;
      display: inline;
    }
    span:first-letter {
      text-transform: uppercase;
    }
    span,
    a {
      margin-left: 5px;
      @include mq($until: xxl) {
        font-size: rem(14px);
      }
      @include mq($until: xs) {
        font-size: rem(18px);
      }
    }
    svg {
      height: 20px;
      fill: $color-main;
      transition: 0.2s;
      margin-right: 5px;
      @include mq($until: xl) {
        height: 21px;
      }
    }
  }

  &__no-results {
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
    font-size: rem(26px);
    color: $border-color;
  }

}



