.offer {
    min-height: 100vh;
    display: flex;
    align-items: center;
    padding-top: 180px;
    padding-bottom: 100px;
    //overflow: hidden;
    &__content {
        display: grid;
        grid-template-columns: 750px 1fr;
        grid-template-rows: 1fr;
        grid-column-gap: 80px;
        width: 100%;
        align-items: start;
        h2 {
            font-size: rem(29px);
            font-weight: 300;
            text-transform: uppercase;
            padding: 0 40px;
            margin-bottom: 70px;
        }
    }
    &__preview {
        h3 {
            position: relative;
            z-index: 1;
            font-size: rem(23px);
            font-weight: 400;
            margin-bottom: 50px;
            padding-left: 8vw;
            color: #fff;
            text-align: center;
        }
        &__image {
            /*background-image: url(/themes/default/assets/images/icons/pattern.svg);
            background-position: center;
            background-size: cover;
            background-attachment: fixed;*/
            position: relative;
            a {
                position: relative;
                display: inline-block;
            }
            .back {
                position: absolute;
                top: -120px;
                right: -8vw;
                width: 100%;
                height: 100%;
                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 0;
                    background-color: #1A4265;
                    border-radius: 0 3px 3px 0;
                }
                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 0;
                    background-image: url('/themes/default/assets/images/oferta.jpg');
                    background-position: left bottom;
                    background-size: cover;
                    border-radius: 0 3px 3px 0;
                    mix-blend-mode: soft-light;
                    opacity: 0.6;
                }
            }
            img {
                z-index: 1;
                position: relative;
                border-radius: 3px;
                box-shadow: 0 3px 6px rgba(9, 52, 82, 0.15);
            }
            .zoom {
                position: absolute;
                bottom: 30px;
                right: 30px;
                font-size: rem(16px);
                font-weight: 500;
                z-index: 2;
                background-color: #1A4265;
                color: #fff;
                padding: 0 10px;
                border-radius: 3px;
                transition: ease 0.3s background-color;
                &:hover {
                    background-color: $color-main;
                }
                img {
                    padding: 5px;
                }
            }
        }
    }
    .gallery {
        display: block;
    }
}

@include mq($until: 1600px) {
    .offer {
        &__content {
            grid-template-columns: 1fr;
            h2 {
                text-align: center;
            }
        }
        &__preview {

            max-width: 800px;
            margin: 140px auto 0 auto;
        }
    }
}

@include mq($until: xl) {
    .offer {
        &__preview {
            &__image {
                .back {
                max-width: 80%;
                    right: -100px;
                }
            }
        }
    }
}
@include mq($until: lg) {
    .offer {
        &__preview {
            &__image {
                .back {
                    right: -50px;
                }
            }
        }
    }
}
@include mq($until: md) {
    .offer {
        padding-top: 100px;
        &__content {
            h2 {
                padding: 0;
            }
        }
        &__preview {
            display: none;
            &__image {
                .back {
                    max-width: 85vw;
                }
            }
        }
    }
}
@include mq($until: sm) {
    .offer {
        &__preview {
            &__image {
                .back {
                    right: -30px;
                }
            }
        }
    }
}
@include mq($until: xs) {
    .offer {
        padding-top: 60px;
        padding-bottom: 60px;
        h2 {
            margin-bottom: 60px;
        }
        &__preview {
            &__image {
                .back {
                    right: -15px;
                }
            }
        }
    }
}
