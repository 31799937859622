.footer {
  background-image: url('/themes/default/assets/images/footer.jpg');
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  &::after {
    content: '';
    background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
    width: 100%;
    height: 140px;
    position: absolute;
    top: 0;
    left: 0;
  }
  &__text {
    h2 {
      color: #fff;
      font-size: rem(29px);
      font-weight: 700;
      text-transform: uppercase;
      max-width: 430px;
      margin-top: 40px;
      margin-bottom: 40px;
      display: inline-block;
    }
  }
  &__contact {
    background-color: rgba(26, 66, 101, 0.93);
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    grid-column-gap: 30px;
    .logo {
      display: flex;
      align-items: center;
      margin-bottom: 70px;
      img {
        margin-right: 30px;
      }
      p {
        color: #fff;
        font-size: rem(16px);
      }
    }
    .links {
      display: flex;
      flex-wrap: wrap;
      row-gap: 20px;
      align-items: flex-start;
      p {
        color: #fff;
        font-size: rem(16px);
        font-weight: 700;
        margin-bottom: 20px;
        width: 100%;
      }
      a {
        background-color: #fff;
        border-radius: 5px;
        padding: 5px 10px;
        display: flex;
        align-items: center;
        transition: ease 0.3s background-color;
        margin-right: 15px;
        span {
          color: #1A4265;
          white-space: nowrap;
          margin-left: 10px;
          font-size: rem(16px);
          transition: ease 0.3s color;
        }
        img {
          width: 20px;
        }
        &:hover {
          background-color: $color-main;
          span {
            color: #fff;
          }
        }
      }
    }
    .right {
      max-width: 820px;
      width: 100%;
    }
    .social {
      text-align: right;
      span {
        color: rgba(255, 255, 255, 0.6);
        font-size: rem(14px);
      }
      a {
        opacity: 0.6;
        margin-left: 20px;
        transition: ease 0.3s opacity;
        &:hover {
          opacity: 1;
        }
      }
      img {
        width: 27px;
      }
    }
    form {
      margin-top: -300px;
    }
  }
}

.footer-small{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 10px;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    &__copyright {
      color: #707175;
      font-size: rem(16px);
      text-align: center;
    }
    a {
      color: #707175;
      font-size: rem(16px);
      &:hover, &:hover span {
        color: $color-main;
      }
    }
    &__realization{
      text-align: right;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      span{
        color: #707175;
        font-size: rem(16px);
        transition: color .3s ease-in-out;
      }
      img{
        margin-left: 15px;
      }
    }
}
@include mq($until: 1280px) {
  .footer {
    &__contact .right {
      max-width: 400px;
      width: 100%;
    }
    .form {
      textarea {
        height: 140px;
      }
      .submitSection {
        grid-template-columns: 1fr;
        grid-row-gap: 20px;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        .form__rodo {
          min-width: unset;
        }
      }
      &__top {
        grid-template-columns: 1fr;
        grid-row-gap: 20px;
      }
    }
  }
}
@include mq($until: md) {
  .footer {
    .logo {
      align-items: flex-start;
      flex-direction: column;
      img {
        margin-bottom: 30px;
      }
      p {
        font-size: rem(20px);
      }
    }

    &::after {
      display: none;
    }
    &__text {
      display: none;
    }
    &__contact {
      flex-direction: column;
      align-items: stretch;
      .logo {
        margin-bottom: 40px;
        justify-content: space-between;    
      }
      .right {
        max-width: 100%;
      }
      form {
        margin-top: 40px;
        max-width: 100%;
      }
      .social {
        text-align: left;
      }
    }
  }
  .footer-small {
    display: flex;
    margin-bottom: 60px;
    justify-content: center;
  }

}

@include mq($until: sm) {
  .footer {
    &__contact {
      .links {
        justify-content: space-evenly;
        flex-direction: column;      
        a {
          margin-right: 0;
          span {
            font-size: rem(20px);
          }
        }
        p {
          font-size: rem(20px);
        }
      }
      .social {
        display: flex;
        justify-content: space-between;
        span {
          font-size: rem(20px);
        }
      }
    }
  }
  .footer-small {
    flex-direction: column;
    text-align: center;
  }
}

