.about {
    padding-top: 18vh;
    padding-bottom: 100px;
    display: flex;

    &__content {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 50px;
        grid-row-gap: 100px;
        align-items: center;
        width: 100%;

        h2 {
            font-size: rem(29px);
            text-transform: uppercase;
            font-weight: 400;
            margin-bottom: 0;
        }

        h3 {
            font-size: rem(23px);
            font-weight: 500;
            border-bottom: 2px solid #1A4265;
            padding-bottom: 40px;
            margin-top: 0;
        }

        p {
            line-height: rem(32px);
        }
    }

    &__icons {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 50px;
        grid-row-gap: 30px;
    }

    &__icon {
        text-align: center;

        img {
            max-width: 85px;
            width: 100%;
        }
        h4 {
            font-size: rem(16px);
            font-weight: 600;
            margin-top: 30px;
            span {
                display: block;
            }
        }
    }
}

@include mq($until: lg) {
    .about {
        padding-top: 100px;
        &__content {
            display: flex;
            flex-direction: column;
            grid-row-gap: 30px;
            h2 {
                text-align: center;
                max-width: 800px;
                margin-bottom: 30px;
            }
            h3 {
                border-bottom: none;
                max-width: 600px;
                text-align: center;
                padding-bottom: 0;
            }
        }
    }
}

@include mq($until: sm) {
    .about {
        &__icon h4 span {
            font-size: rem(20px);
        }
        &__content p {
            font-size: rem(20px);
        }
    }
}

@include mq($until: xs) {
    .about {
        padding-top: 60px;
        &__icons {
            display: flex;
            flex-direction: column;
            width: 100%;
        }
        &__icon {
            display: flex;
            align-items: center;
            border: 1px solid rgba(220, 138, 52, 0.2);
            padding: 20px;
            border-radius: 5px;
            img {
                margin-right: 20px;
            }
            h4 {
                margin-top: 0;
                margin-bottom: 0;
                text-align: left;
                span {
                    display: inline;
                }
            }
        }
    }
}
