.photos {
    background-color: #F6F8F9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;

    h2 {
        font-size: rem(29px);
        font-weight: 300;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 90px;
    }
    .slick-slide {
        border-radius: 3px;
        margin-left: -14vw;
        max-width: 800px;
        box-shadow: 0 3px 21px rgba(9, 52, 82, 0.15);
        &:nth-child(2n) {
            margin-top: 16vh;
        }
        &:last-of-type {
            margin-left: 0;
        }
        &:first-of-type {
            margin-left: 0;
        }
        img {
            height: auto;
        }
    }
    .slick-track {
        padding: 20px 0;
    }

    .slick-slide * {
        font-size: 0;
        border-radius: 3px;
    }

}

@include mq($until: xl) {
    .photos {
        .slick-slide {
            max-width: 600px;
        }    
    }
}
@include mq($until: lg) {
    .photos {
        .slick-slide {
            margin-left: -18vw;
        }    
    }
}

@include mq($until: sm) {
    .photos {
        .slick-slide {
            margin-left: -24vw;
            max-width: 400px;
        }    
    }
}

@include mq($until: xs) {
    .photos {
        padding-top: 60px;
        padding-bottom: 60px;
        h2 {
            margin-bottom: 40px;
        }
        .slick-slide {
            max-width: 300px;
        }    
    }
}
