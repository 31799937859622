.hero {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-top: 200px;
    overflow: hidden;
    position: relative;
    z-index: 0;
    .slick-slider {
        position: absolute;
        top:0;
        z-index: 0;
        * {
            font-size: 0;
        }
    }
    .slick-track {
        padding: 0;
    }
    .single-slide {
        width: 100%;
        height: 100vh;
        background-position: center 75%;
        background-size: cover;
    }
    &__content {
        position: relative;
        z-index: 1;
        h1 {
            font-size: rem(34px);
            font-weight: 700;
            color: #fff;
            max-width: 550px;
            width: 100%;
            text-shadow: 0 3px 26px #093352;
            text-transform: uppercase;
        }
    }
}

@include mq($until: md) {
    .hero {
        min-height: unset;
        padding-top: 0;
        .slick-slider {
            position: relative;
        }        
        .single-slide {
            height: auto;
            aspect-ratio: 4/3;
        }
        &__content {
            width: 100%;
            background: $color-main;
            h1 {
                text-shadow: none;
                margin-top: 30px;
                margin-bottom: 30px;
            }
        }
    }
}

@include mq($until: xs) {
    .hero {
        &__content {
            h1 {
                text-align: center;
                font-size: rem(30px);
            }
        }
    }
}

