.water {
    background-image: url('/themes/default/assets/images/icons/pattern.svg');
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    min-height: 120vh;
    display: flex;
    align-items: center;
    position: relative;
    padding-top: 100px;
    padding-bottom: 100px;
    &::after {
        content: '';
        position: absolute;
        top: -1px;
        left: 0;
        width: 100%;
        height: 100.5%;
        z-index: 0;
        background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.95) 20%, rgba(255,255,255,0.95) 70%, rgba(255,255,255,1) 100%);
    }
    &__content {
        z-index: 1;
        display: grid;
        grid-template-columns: 1fr 440px;
        grid-template-rows: 1fr;
        grid-column-gap: 8vw;
        width: 100%;
        align-items: start;
        //min-height: 100vh;
    }
    &__selectors {
        //display: flex;
        //flex-direction: column;
        h2 {
            color: #fff;
            font-size: rem(23px);
            font-weight: 400;
            margin-bottom: 50px;
            z-index: 1;
            position: relative;
        }
        > div {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: 1fr;
            grid-column-gap: 20px;
            flex: 1;
            position: relative;
            .back {
                position: absolute;
                top: -120px;
                width: 100%;
                height: 100%;
                left: -8vw;
                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 0;
                    background-color: #74941D;
                    border-radius: 0 3px 3px 0;
                }
                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 0;
                    background-image: url('/themes/default/assets/images/lokalizacja.jpg');
                    background-position: right center;
                    background-size: cover;
                    border-radius: 0 3px 3px 0;
                    mix-blend-mode: soft-light;
                }
            }
        }
    }
    &__selector {
        background-color: #1f4065;
        background-size: cover;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        position: relative;
        cursor: pointer;
        border-radius: 3px;
        min-height: 70vh;
        z-index: 1;
        h4 {
            color: #fff;
            font-size: rem(22px);
            font-weight: 700;
            text-transform: uppercase;
            z-index: 1;
            margin-top: 70px;
            margin-bottom: 70px;
            opacity: 0.5;
            transition: ease 0.3s all;
            padding-left: 20px;
            padding-right: 20px;
            text-align: center;
        }
        &#baltyk {
            background-image: url('/themes/default/assets/images/baltyk.jpg');
            background-position: right center;
            transition: ease 0.3s all;
            &:hover, &.active {
                background-image: url('/themes/default/assets/images/baltyk-hover.jpg');
            }
        }
        &#jamno {
            background-image: url('/themes/default/assets/images/jamno.jpg');
            background-position: left center;
            &:hover, &.active {
                background-image: url('/themes/default/assets/images/jamno-hover.jpg');
            }
        }
        &::after {
            content:'';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(38, 76, 109, 0.5) 75%, #1A4265 100%);
            opacity: 0.6;
            z-index: 0;
            border-radius: 3px;
            mix-blend-mode: multiply;
            transition: ease 0.3s all;
        }
        &.active, &:hover {
            h4 {
                font-size: rem(27px);
                opacity: 1;
            }
            &::after {
                opacity: 1;
            }
        }
    }
    &__info {
        display: none;
        &.active {
            display: block;
        }
        h2 {
            color: #74941D;
            font-size: rem(29px);
            font-weight: 300;
            text-transform: uppercase;
            margin-bottom: 50px;
        }
        p {
            line-height: rem(32px);
            font-size: rem(16px);
        }

    }
}

@include mq($until: xl) {
    .water {
        &__content {
            grid-template-columns: 1fr;
        }
        &__selectors {
            margin-bottom: 70px;
            div > .back {
                max-width: 80%;
                left: -100px;
            }
        }
        &__selector {
            min-height: 40vh;
            h4 {
                margin-bottom: 50px;
            }
        }
        &__info h2 {
            text-align: center;
        }
    }
}
@include mq($until: lg) {
    .water {
        &__selectors {
            div > .back {
                left: -50px;
            }
        }
    }
}
@include mq($until: md) {
    .water {
        &__selectors {
            h2 {
                padding-right: 20vw;
            }
            div > .back {
                max-width: 85vw;
            }
        }
    }
}
@include mq($until: sm) {
    .water {
        &__selectors {
            h2 {
                padding-right: 30px;
            }
            div > .back {
                left: -30px;
            }
        }
        &__selector {
            h4 {
                margin-bottom: 30px;
            }
        }
        &__info p {
            font-size: rem(20px);
        }
    }
}
@include mq($until: xs) {
    .water {
        padding-bottom: 60px;
        &__selectors {
            h2 {
                padding-right: 15vw;
            }
            div {
                grid-column-gap: 0;
            }
            div > .back {
                left: -15px;
            }
        }
        &__selector {
            border-radius: 5px 0 0 5px;
            &:last-of-type {
                border-radius: 0 5px 5px 0;
            }
            h4 {
                margin-bottom: 20px;
            }
        }
    }
}
