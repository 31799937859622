.header {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  position: absolute;
  min-height: 150px;
  top: 0;
  left: 0;
  width: 100%;
  transition: background-color .3s ease-in-out;
  z-index: 2;

  &--fixed {
    position: fixed;
    width: 100%;
    transition: background-color .3s ease-in-out;

    &.sticky {
      background-color: lemonchiffon;
    }
  }

  &__logo{
    position: relative;
    z-index: 2;
    background-color: #fff;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    img {
      height: auto;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__contact {
    display: flex;
    justify-content: flex-end;

    a {
      background: #1A4265;
      border-radius: 5px;
      padding: 5px 10px ;
      display: flex;
      align-items: center;
      transition: ease 0.3s background-color;
      img {
        width: 20px;
      }
      span {
        margin-left: 10px;
        color: #fff;
      }
      &:first-of-type {
        margin-right: 10px;
      }
      &:hover {
        background-color: #DE8730;
      }
    }
  }

  &__nav {
      margin-top: 20px;

    &--fullscreen {
      width: 100%;
      height: 100vh;
      background-color: $color-extra;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      padding-top: 100px;
      transform: translate(0, -100%);
      transition: transform 0.3s ease-in-out;
    }
    &--visible {
      transform: translate(0, 0);
    }
  }

  &__menuList {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    &--fullscreen {
      flex-direction: column;
    }
  }

  &__menuItem {
    margin-left: 15px;
    margin-top: 0;
    padding: 0;
    &--fullscreen{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
    }
    &::before {
      display: none;
    }
  }

  &__menuLink {
    color: #fff;
    font-size: rem(18px);
    font-weight: 500;
    text-transform: uppercase;
    padding: 10px;
    display: block;
    transition: ease 0.3s background-color;
    border-radius: 5px;
    text-shadow: 0 3px 11px #093352a3, 2px 1px 3px #093352a3;

    &--fullscreen {
      color: #ffffff;
      font-size: rem(28px);
    }
    &:active, &--active, &--open, &:hover {
      color: #fff;
      background-color: $color-main;
      text-shadow: none;
    }
  }
}
@include mq($until: md) {
  .menu-opened .header {
    background-color: #fff;
  }
  .header {
    min-height: 80px;
    &__content {
      align-items: center;
      flex-direction: row;
    }
    &__nav {
      margin-top: 0;
    }
    &__contact {
      position: fixed;
      bottom: 0;
      left: 0;
      background-color: #1A4265;
      width: 100%;
      height: 60px;
      justify-content: center;
      align-items: center;
      a:first-of-type {
        margin-right: 0;
      }
    }
  }
}
@include mq($until: sm) {
  .header {
    &__contact {
      a span {
        font-size: rem(20px);
      }
    }
  }
}
@include mq($until: xs) {
  .header {
    position: relative;
    &__logo {
      max-width: 80px;
    }
    &__hamburger {
      span {
        background-color: #1A4265;
      }
    }
  }
}

@include mq($until: 358px) {
  .header {
    &__contact {
      a:last-of-type {
        span {display: none;}
      }
    }
  }
}
