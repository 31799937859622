.map {
    min-height: 100vh;
    overflow: hidden;
    &__content {
        h2 {
            color: #A4C0FD;
            font-size: rem(29px);
            font-weight: 400;
            text-transform: uppercase;
            margin-bottom: 30px;
            margin-top: 30px;
            text-align: center;
        }
    }
    &__wrapper {
        min-height: 90vh;
        position: relative;
    }
    &__insert {
        width: 100%;
        height: 100%;
        z-index: 0;
        position: absolute!important;
        top: 0;
    }
    &__places {
        max-width: 500px;
        width: 100%;
        padding: 50px;
        background-color: rgba(255, 255, 255, 0.9);
        border-radius: 3px;
        z-index: 1;
        position: relative;
        margin-left: 8vw;
        margin-top: 100px;
        margin-bottom: 100px;
        display: inline-block;
        box-shadow: 0 3px 6px rgba(9, 52, 82, 0.15);
    
        h3 {
            font-size: rem(23px);
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 10px;
            position: relative;
            padding-right: 40px;
        }
        p {
            font-size: rem(18px);
            margin-bottom: 0;
        }
        ul {
            border-top: 1px solid #C4CDE2;
            margin-top: 30px;
            margin-bottom: 0;
            padding-top: 30px;
        }
        li {
            font-size: rem(23px);
            line-height: rem(23px);
            font-weight: 500;
            padding: 0;
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            &::before {
                display: none;
            }
            &:last-of-type {
                margin-bottom: 0;
            }
        }
        span {
            font-size: rem(23px);
            line-height: rem(23px);
            font-weight: 700;
            padding-left: 20px;
            white-space: nowrap;
        }
        .button {
            display: none;
            margin-top: 30px;
        }
        .close {
            background-color: #C4CDE2;
            border-radius: 100%;
            padding: 5px;
            opacity: 1;
            transition: ease 0.3s background-color;
            position: absolute;
            right: 0;
            top: 0;
            &:hover {
                opacity: 1;
                background-color: $color-main;
            }
        }
    }
}

@include mq($until: lg) {
    .map {
        &__places {
            max-width: 400px;
            padding: 30px;
        }
    }
}
@include mq($until: md) {
    .map {
        &__places {
            margin-left: 50px;
            margin-right: 0;
            margin-top: 50px;
            margin-bottom: 50px;
        }
    }
}
@include mq($until: sm) {
    .map {
        &__places {
            margin-top: 50px;
            margin-bottom: 50px;
            p {
                font-size: rem(20px);
            }
        }
    }
}
@include mq($until: xs) {
    .map {
        &__places {
            margin-left: 0;
            margin-top: 5vw;
            max-width: 90vw;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}
