.cookie{
    position: fixed;
    bottom: 0;
    z-index: 9999;
    background: rgba(26, 66, 101, 0.9);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 75px;
    padding: 15px 30px;
    @include mq($until: sm) {
        min-height: 50px;
        padding: 10px 30px;
    }
    @include mq($until: xs) {
        flex-wrap: wrap;
        justify-content: center;
        min-height: 100px;
    }
    &__text{
        color: #FFFFFF;
        width: 100%;
        text-align: center;
        margin-right: 15px;
        font-size: rem(14px);
        span{
            color: inherit;
            font-size: inherit;
        }

        @include mq($until: xs) {
            margin-right: 0;
            margin-bottom: 10px;
        }
    }
    &__accept{
        background-color: #ffffff;
        color: #1A4265;
        padding: 5px 10px;
        font-size: rem(14px);
        border-radius: 5px;
        transition: ease 0.3s all;
        &:hover {
            background-color: $color-main;
            color: #fff;
        }
        @include mq($until: xs) {

        }
    }
    &__more{
        color: $color-main;
    }
}
