.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: rem(50px);
  background: linear-gradient(180deg, rgba(245, 247, 247, 0.87) 0%, rgba(245, 247, 247, 0.93) 25%, #F5F7F7 50%,  #F5F7F7 100%);
  padding: 40px;
  border-radius: 8px;
  max-width: 820px;
  @include mq($until: xs) {
    padding: 20px;
  }
  :focus{outline: none;}

  &__top {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 30px;
    margin-bottom: 30px;
  }

  &__row {
    position: relative;
  }

  &__input {
    margin-top: 5px;
    border: 0;
    padding: 4px 0;
    border-bottom: 1px solid #1A4265;
    background-color: transparent;
    position: relative;
    font-size: rem(16px);
    font-weight: 500;
    width: 100%;
    color: #1A4265;

    &--checkbox{
      margin: 0 5px 0 0 ;
    }
    &--textarea{
      margin-bottom: -7px;
      resize: none;
    }

    & ~ .form__border {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 2px;
      background-color: $color-main;
      transition: 0.3s;
    }

    &:focus ~ .form__border {
      width: 100%;
      transition: 0.3s;
    }

    &:focus ~ .form__label {
      top: -16px;
      font-size: 12px;
      color: $color-main;
      transition: 0.3s;
    }

    &--has-content ~ .form__border {
      width: 100%;
      transition: 0.4s;
    }

    &--has-content ~ .form__label {
      top: -16px;
      font-size: 12px;
      color: $color-main;
      transition: 0.3s;
    }

  }

  &__label  {
    position: absolute;
    left: 0;
    width: 100%;
    top: 0px;
    color: #595F64;
    transition: 0.3s;
    letter-spacing: 0.5px;
    display: block;
    margin-bottom: 10px;
    font-size: rem(16px);
    font-weight: 500;
  }

  .submitSection {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 40px;
    align-items: center;
    margin-top: 30px;
  }

  &__rodo {
    font-size: rem(14px);
    width: 100%;
    min-width: 310px;
    label {
      display: grid;
      grid-template-columns: 40px auto;
      grid-template-rows: 1fr;
      grid-column-gap: 20px;
      position: relative;
      align-items: center;
      cursor: pointer;
      p {
        color: #595F64;
        font-size: rem(12px);
        line-height: rem(12px);
        @include mq($until: sm) {
          font-size: rem(16px);
          line-height: rem(16px);
        }
      }
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        &:checked ~ .checkmark {
          background-color: $color-main;
        }
        &:checked ~ .checkmark:after {
          opacity: 1;
        }
      }
      .checkmark {
        height: 40px;
        width: 40px;
        background-color: #D1D1D1;
        border-radius: 8px;
        transition: ease 0.3s background-color;
        &:after {
          content: "";
          position: absolute;
          left: 14px;
          top: 10px;
          width: 10px;
          height: 18px;
          border: solid #fff;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
          opacity: 0;
          transition: ease 0.3s opacity;
        }        
      }
    }
  }
  &__submit {
    color: #1A4265;
    padding: 10px;
    border: none;
    border-radius: 10px;
    background-color: #D1D1D1;
    white-space: nowrap;
    font-size: rem(15px);
    font-weight: 500;
    transition: ease 0.3s all;
    &:hover {
      background-color: $color-main;
      color: #fff;
    }
  }
  select {
    background-color: unset;
  }

  option {
    margin: 5px;
  }

}

#topic {
  font-size: rem(18px);
}

.close {
  &:hover {
    cursor: pointer;
  }
}

.alert{
  h4{
    margin-top: 15px;
    font-size: rem(20px);
  }
}
